import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemedGlobalStyle, appTheme } from '../theme/Theme';
import { MainRoute } from '../modules';
import Modal from 'src/components/Modal';
import Loading from 'src/components/Loading';
import { BrowserRouter } from 'react-router-dom';
import withApp from './App.enhance';
import './App.css';
import './reset.scss';

function App() {
    return (
        <ThemeProvider theme={appTheme(false)}>
            <ThemedGlobalStyle />
            <BrowserRouter>
                <MainRoute />
                <Modal />
                <Loading />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default withApp(App);
